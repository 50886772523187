import React, { useState } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {
  ChevronRight,
  Shield,
  Lightbulb,
  Users,
  Laptop,
  Menu,
  X,
  BarChart,
} from 'lucide-react';

const Logo = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 60' className='h-8'>
    <defs>
      <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='100%'>
        <stop offset='0%' stopColor='#3B82F6' stopOpacity='1' />
        <stop offset='100%' stopColor='#1D4ED8' stopOpacity='1' />
      </linearGradient>
    </defs>
    <g transform='translate(10, 15)'>
      <circle cx='0' cy='0' r='3' fill='url(#gradient)' />
      <circle cx='0' cy='10' r='3' fill='url(#gradient)' />
      <circle cx='0' cy='20' r='3' fill='url(#gradient)' />
      <circle cx='10' cy='20' r='3' fill='url(#gradient)' />
      <circle cx='20' cy='20' r='3' fill='url(#gradient)' />
      <path
        d='M0 3 L0 17 M0 20 L17 20'
        stroke='url(#gradient)'
        strokeWidth='1.5'
        fill='none'
      />
    </g>
    <text
      x='45'
      y='35'
      fontFamily='Arial, sans-serif'
      fontWeight='bold'
      fontSize='24'
      fill='#1E3A8A'
    >
      Lilish
    </text>
    <text
      x='120'
      y='35'
      fontFamily='Arial, sans-serif'
      fontWeight='normal'
      fontSize='24'
      fill='#3B82F6'
    >
      Labs
    </text>
  </svg>
);

// Rest of the components remain the same
const ServiceCard = ({ icon: Icon, title, description }) => (
  <div className='p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow'>
    <div className='flex items-center mb-4'>
      <Icon className='w-8 h-8 text-blue-600 mr-3' />
      <h3 className='text-xl font-bold text-gray-800'>{title}</h3>
    </div>
    <p className='text-gray-600 leading-relaxed'>{description}</p>
  </div>
);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className='bg-white shadow-md fixed w-full z-50'>
      <div className='max-w-7xl mx-auto px-4'>
        <div className='flex justify-between h-16'>
          <div className='flex items-center'>
            <Logo />
          </div>

          {/* Mobile menu button */}
          <div className='flex items-center md:hidden'>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className='text-gray-600'
            >
              {isOpen ? (
                <X className='h-6 w-6' />
              ) : (
                <Menu className='h-6 w-6' />
              )}
            </button>
          </div>

          {/* Desktop menu */}
          <div className='hidden md:flex items-center space-x-8'>
            <a href='#services' className='text-gray-600 hover:text-blue-600'>
              Services
            </a>
            {/*
            <a href='#about' className='text-gray-600 hover:text-blue-600'>
              About
            </a>
            <a href='#contact' className='text-gray-600 hover:text-blue-600'>
              Contact
            </a>
            */}
            <button className='bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700'>
              Get Started
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isOpen && (
          <div className='md:hidden'>
            <div className='pt-2 pb-3 space-y-1'>
              <a
                href='#services'
                className='block px-3 py-2 text-gray-600 hover:text-blue-600'
              >
                Services
              </a>
              <a
                href='#about'
                className='block px-3 py-2 text-gray-600 hover:text-blue-600'
              >
                About
              </a>
              <a
                href='#contact'
                className='block px-3 py-2 text-gray-600 hover:text-blue-600'
              >
                Contact
              </a>
              <button className='w-full text-left px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700'>
                Get Started
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const Hero = () => (
  <div className='pt-24 pb-16 bg-gradient-to-r from-blue-600 to-blue-800'>
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <div className='text-center'>
        <h1 className='text-4xl md:text-5xl font-bold text-white mb-6'>
          Strategic Technology Leadership for Your Business
        </h1>
        <p className='text-xl text-blue-100 mb-8 max-w-3xl mx-auto'>
          Expert CTO consulting services to drive innovation, manage risk, and
          transform your organization
        </p>
        <button className='bg-white text-blue-600 px-8 py-3 rounded-md text-lg font-semibold hover:bg-blue-50'>
          Schedule a Consultation
        </button>
      </div>
    </div>
  </div>
);

const ServicesSection = () => {
  const services = [
    {
      icon: BarChart,
      title: 'Technology Strategy Development',
      description:
        'Develop comprehensive technology strategies aligned with your business objectives. Get expert advice on latest technologies, architecture, and IT infrastructure.',
    },
    {
      icon: Lightbulb,
      title: 'Innovation and Digital Transformation',
      description:
        'Embrace innovation and digital transformation with guided adoption of new technologies, processes, and tools to drive growth and competitive advantage.',
    },
    {
      icon: Shield,
      title: 'Technology Risk Management',
      description:
        'Identify and mitigate technology risks with robust strategies for cybersecurity, compliance, and system reliability to protect your operations.',
    },
    {
      icon: Users,
      title: 'Vendor and Partnership Management',
      description:
        'Make informed decisions on vendor selection and management. Get expert guidance on choosing the right technology partners and negotiating contracts.',
    },
    {
      icon: Laptop,
      title: 'Mentorship and Knowledge Transfer',
      description:
        "Enhance your team's technical capabilities through mentorship and knowledge transfer. Build an innovative culture with cutting-edge skills and best practices.",
    },
  ];

  return (
    <section id='services' className='py-16 bg-gray-50'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='text-center mb-12'>
          <h2 className='text-3xl font-bold text-gray-900 mb-4'>
            Our Services
          </h2>
          <p className='text-gray-600 max-w-2xl mx-auto'>
            Comprehensive CTO consulting services to help your business thrive
            in the digital age
          </p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

const CTASection = () => (
  <div className='bg-blue-600 py-16'>
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <div className='flex flex-col md:flex-row items-center justify-between'>
        <div className='mb-8 md:mb-0 text-center md:text-left'>
          <h2 className='text-3xl font-bold text-white mb-4'>
            Ready to Transform Your Technology Strategy?
          </h2>
          <p className='text-blue-100'>
            Let's discuss how we can help your business achieve its technology
            goals
          </p>
        </div>
        <button className='bg-white text-blue-600 px-8 py-3 rounded-md text-lg font-semibold hover:bg-blue-50 flex items-center'>
          Contact Us Today
          <ChevronRight className='ml-2 h-5 w-5' />
        </button>
      </div>
    </div>
  </div>
);

const Footer = () => (
  <footer className='bg-gray-900 text-gray-300 py-12'>
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
        <div>
          <Logo />
          <p className='text-sm mt-4'>
            Strategic technology consulting services to drive your business
            forward
          </p>
        </div>
        <div>
          <h3 className='text-white text-lg font-bold mb-4'>Contact</h3>
          <p className='text-sm'>Email: info@lilishlabs.com</p>
          <p className='text-sm'>Phone: (xxx) xxx-xxxx</p>
        </div>
      </div>
      <div className='mt-8 pt-8 border-t border-gray-800 text-sm text-center'>
        <p>&copy; 2024 Lilish Labs. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lilish Labs</title>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='assets/favicons/apple-touch-icon.png'
        ></link>
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='assets/favicons/favicon-32x32.png'
        ></link>
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='assets/favicons/favicon-16x16.png'
        ></link>
        <link rel='manifest' href='assets/favicons/site.webmanifest'></link>
        <meta
          name='description'
          content='Strategic technology consulting services to drive your business forward.'
        />
      </Helmet>
      <div className='min-h-screen bg-white'>
        <Navbar />
        <main>
          <Hero />
          <ServicesSection />
          <CTASection />
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default App;
